import NavBar from "../../../Components/NavBar/NavBar";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../axios";
import React, {useEffect, useMemo, useState} from "react";
import './Ticket.css'
import banner from "../../../assets/images/event-ticket.jpg";

import EventCreateOrder from "../Payment/EventCreateOrder";
import Footer from "../../../Components/Footer/Footer";

function Ticket() {
    const {subEventId, eventId} = useParams();
    const navigate = useNavigate();
    const [subEvent, setSubEvent] = useState(null);
    const [event, setEvent] = useState(null);
    const [tickets, setTickets] = useState([]);

    const [selectedTicket, setSelectedTickets] = useState({});

    const ticketHasSelected = useMemo(() => {
        return !!Object.values(selectedTicket).reduce((total, value) => total + value.count, 0);
    }, [selectedTicket])

    const sumPrice = useMemo(() => {
        const total = Object.values(selectedTicket).reduce((sum, ticket) => {
            return sum + parseFloat(ticket.price || 0) * (ticket.count || 0);
        }, 0);

        return total.toFixed(3); // بعد از محاسبه نهایی، مقدار را گرد کن
    }, [selectedTicket]);

    const [step, setStep] = useState(1);
    const bgColors = ['bg-primary', 'bg-warning', 'bg-black'];
    const borderColors = ['border-primary', 'border-warning', 'border-black'];
    

    function fetchSubEvent() {
        return axiosInstance.get('/website/sub-events/' + subEventId)
            .then((response) => {
                setSubEvent(response.data.data);

                // setGallery([]);
                // const galleryIds = response.data.gallery_id ? response.data.gallery_id.split(',') : [];
                // galleryIds.forEach((galleryId) => {
                //     fetchEventGallery(galleryId)
                // })
            })
    }

    function fetchTickets() {
        axiosInstance.get('/website/tickets', {
            params: {
                event_id: eventId,
                sub_event_id: subEventId,
            }
        })
            .then((response) => {
                setTickets(response.data.data);
                response.data.data.forEach(ticket => {
                    setSelectedTickets(prevState => {
                        return {
                            ...prevState,
                            [ticket.id]: {
                                ...ticket,
                                count: 0
                            }
                        }
                    })
                })
            })
    }

    function fetchEvent() {
        return axiosInstance.get('/website/events/' + eventId)
            .then((response) => {
                setEvent(response.data);
            })
    }

    useEffect(() => {
        fetchSubEvent()
        fetchEvent()
        fetchTickets()
    }, []);

    return (
        <>
            <div className="event-container content-page">
                <header>
                    <NavBar/>
                </header>

                <main className='container'>

                    {
                        (subEvent && event && tickets) &&
                        <div>

                            <div className={'py-3 d-flex align-items-center justify-content-between'}>
                                <button onClick={() => {
                                    if (step !== 1) {
                                        setStep(1)
                                    } else {
                                        navigate(-1)
                                    }
                                }}
                                className={'btn border rounded-circle py-2'}
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.91016 4.07992L15.4302 10.5999C16.2002 11.3699 16.2002 12.6299 15.4302 13.3999L8.91016 19.9199"
                                            stroke="#A1A2A5" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>

                                <p className={'fs-6 fw-semibold'}>{step === 1 ? 'إحجز التذكرة' : 'التأكيد النهائي'}</p>

                                <button className={'opacity-0 btn'}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.91016 4.07992L15.4302 10.5999C16.2002 11.3699 16.2002 12.6299 15.4302 13.3999L8.91016 19.9199"
                                            stroke="#A1A2A5" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>

                            {
                                step === 1 && <div className='m-3 pb-5 mb-5'>
                                    <p className='mt-3 fw-semibold'>في هذه المرحلة يمكنك اختيار الكرسي الذي تريده بناءً على
                                        الشروط والميزات المتاحة.</p>

                                    <img  src={banner} alt="sits" className='w-100 rounded-2 mt-2'/>

                                    <div className='mt-3 fw-semibold'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                                stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#292D32"
                                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                        <span className='mx-2'>اختر فئة مقعدك</span>
                                    </div>

                                    <div className={'mt-3'}>
                                        {
                                            tickets.map((ticket, index) => (
                                                <div
                                                    key={ticket.id + index}
                                                    className={`rounded-3 ${borderColors[index % borderColors.length]} ${bgColors[index % bgColors.length]} border overflow-hidden mb-2`}
                                                    >
                                                    <div className={' p-3'}>
                                                        <div className='text-white fs-5 fw-semibold'>{ticket.title}</div>


                                                        <div
                                                            className='d-flex align-items-center justify-content-between text-white fs-6 fw-medium'>
                                                            <p>{ticket.content}</p>

                                                            <p>{ticket.quantity} تذكرة متبقية</p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className='d-flex justify-content-between align-items-center bg-white p-3'>
                                                        <div className={'fw-medium'}>رقم</div>
                                                        <div className='d-flex align-items-center gap-3'>
                                                            <button className='btn'
                                                                    onClick={() => setSelectedTickets(prevState => {
                                                                        return {
                                                                            ...prevState,
                                                                            [ticket.id]: {
                                                                                ...ticket,
                                                                                count: prevState[ticket.id].count + 1
                                                                            }
                                                                        }
                                                                    })}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                    width={24}
                                                                    viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="currentColor" className="border rounded-circle">
                                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                                          d="M12 4.5v15m7.5-7.5h-15"/>
                                                                </svg>
                                                            </button>

                                                            {selectedTicket[ticket.id].count}

                                                            <button
                                                                onClick={() => setSelectedTickets(prevState => {
                                                                    return {
                                                                        ...prevState,
                                                                        [ticket.id]: {
                                                                            ...ticket,
                                                                            count: prevState[ticket.id].count !== 0 ? prevState[ticket.id].count - 1 : 0
                                                                        }
                                                                    }
                                                                })}
                                                                className={'btn'}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                     viewBox="0 0 24 24" stroke-width="1.5"
                                                                     width={24}
                                                                     stroke="currentColor"
                                                                     className="border rounded-circle ">
                                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                                          d="M5 12h14"/>
                                                                </svg>
                                                            </button>
                                                        </div>

                                                        <div>
                                                            {ticket.price} OMR
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className={'text-muted mt-2'}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.99935 18.3337C14.5827 18.3337 18.3327 14.5837 18.3327 10.0003C18.3327 5.41699 14.5827 1.66699 9.99935 1.66699C5.41602 1.66699 1.66602 5.41699 1.66602 10.0003C1.66602 14.5837 5.41602 18.3337 9.99935 18.3337Z"
                                                stroke="#A1A2A5" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path d="M10 6.66699V10.8337" stroke="#A1A2A5" stroke-width="1.5"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9.99609 13.333H10.0036" stroke="#A1A2A5" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                        <span className={'mx-2'}>تم اقتراح أنواع مختلفة من الكراسي لتلبية احتياجات الضيوف. تؤثر المسافة بين الكراسي والمسرح وجودة الخدمة على التكلفة النهائية، لذا فإن اختيار الكرسي المناسب وموقعه يعزز تجربة الضيوف.</span>
                                    </div>
                                </div>
                            }

                            {
                                step >= 2 && <div>

                                    <div className='bg-light p-2 rounded-2'>
                                        <div className='mt-3 fw-semibold'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#292D32"
                                                      stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                            </svg>

                                            <span className='mx-2'>معلومات الحدث</span>
                                        </div>

                                        <p className='fs-4 fw-bold my-2'>{subEvent.title}</p>

                                        <p>{subEvent.description}</p>

                                        <p className='fs-4 fw-bold my-2'>{event.date_short}</p>

                                        <div>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.0009 13.4304C13.724 13.4304 15.1209 12.0336 15.1209 10.3104C15.1209 8.5873 13.724 7.19043 12.0009 7.19043C10.2777 7.19043 8.88086 8.5873 8.88086 10.3104C8.88086 12.0336 10.2777 13.4304 12.0009 13.4304Z"
                                                    stroke="#2F65CC" stroke-width="1.5"/>
                                                <path
                                                    d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z"
                                                    stroke="#2F65CC" stroke-width="1.5"/>
                                            </svg>

                                            <span className='mx-2 event-description fw-semibold'
                                                  style={{color: '#2F65CC'}}>
                                            {event.address}
                                        </span>
                                        </div>
                                    </div>

                                    <div className={'bg-light p-2 mt-2'}>
                                        <div className='mt-3 fw-semibold'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#292D32"
                                                      stroke-width="1.5" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                            </svg>

                                            <span className='mx-2'>التذاكر</span>
                                        </div>

                                        <div>
                                            <div className={'mt-2 border rounded-2 overflow-hidden'}>
                                                <div
                                                    className={'bg-body-secondary d-flex justify-content-between align-items-center p-2'}>
                                                    <p className={'fs-medium'}>یوم</p>
                                                    <p className={'fs-medium'}>وقت البدء</p>
                                                </div>

                                                <div
                                                    className={' d-flex justify-content-between align-items-center p-2 bg-white'}>
                                                    <p className='fs-6 fw-semibold my-2'>{event.date_short}</p>
                                                    <p className='fs-6 fw-semibold my-2'>{subEvent.start_time}</p>
                                                </div>
                                            </div>

                                            <div className={'mt-2 border rounded-2 overflow-hidden'}>
                                                <table className="table text-center">
                                                    <thead>
                                                    <tr>
                                                        <th className={'bg-body-secondary text-start'}>نوع التذكرة</th>
                                                        <th className={'bg-body-secondary'}>الرقم</th>
                                                        <th className={'bg-body-secondary text-end'}>السعر</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        Object.values(selectedTicket).map((item, index) => (
                                                            item.count > 0 && <tr key={item.price}>
                                                                <td className={'text-start'}>{item.title}</td>
                                                                <td>{item.count}</td>
                                                                <td className={'text-end'}>
                                                                    <strong>OMR {(parseFloat(item.price) * item.count).toFixed(3)}</strong>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className={'mt-2 border rounded-2 overflow-hidden'}>
                                                <div
                                                    className={' d-flex justify-content-between align-items-center p-2 bg-white'}>
                                                    <p className='fs-6 fw-semibold my-2'>إجمالي السعر</p>
                                                    <p className='fs-6 fw-semibold my-2'>{sumPrice} OMR</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='event-action'>
                                <div className={'container mx-3 d-flex align-items-center justify-content-between'}>
                                    <p>
                                        {
                                            ticketHasSelected ? sumPrice+' OMR' : 'اختر تذكرة'
                                        }
                                    </p>


                                    <button onClick={() => {
                                        setStep(prevState => prevState += 1)
                                    }}
                                            className='btn btn-primary '
                                            disabled={!ticketHasSelected}>الصفحة التالية
                                    </button>
                                </div>
                            </div>

                            <EventCreateOrder
                                step={step}
                                totalPrice={sumPrice}
                                selectedTicket={selectedTicket}
                                setStep={setStep}
                                event={event}
                            />
                        </div>

                    }

                </main>

            </div>
        </>
    )
}

export default Ticket;