import NavBar from "../../Components/NavBar/NavBar";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../axios";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setFixedPrices, setSelectedProducts, setTotalPrice} from "../../Redux/catalog";
import TickIcon from "../../assets/Icons/TickIcon";
import CatalogStoreOrderFactor from "../Components/Catalog/Restaurant/Order/CatalogStoreOrderFactor";
import OrderList from "./OrderList";

function OrderPaymentStatusError() {
    const {orderId} = useParams();
    const {i18n, t} = useTranslation();
    const {selectedProducts, fixedPrices} = useSelector((state) => state.catalog);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [order, setOrder] = useState(null);
    const [orderItems, setOrderItems] = useState([]);

    function fetchOrder() {
        return axiosInstance.get('website/orders/' + orderId, {
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                setOrder(response.data);
                fetchFixedPrices(response.data);
            })
    }

    function fetchOrderItems() {
        return axiosInstance.get('website/order-items', {
            params: {
                order_id: orderId
            },
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then(response => {
                const data = response.data.map((item) => {
                    return {
                        ...item.product,
                        _count: item.quantity,
                        initial_price: item.final_price,
                    }
                })

                setOrderItems(response.data)
                dispatch(setSelectedProducts(data))
            })
    }

    function fetchFixedPrices(order) {
        return axiosInstance.get('website/catalog-fixed-prices', {
            params: {
                catalog_id: order.catalog_id
            },
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                dispatch(setFixedPrices(response.data));
            })
    }

    return (
        <div className="content-page">
            <div className="">
                <header>
                    <NavBar/>
                </header>

                <main>
                    error payment
                </main>
            </div>
        </div>
    )
}

export default OrderPaymentStatusError;