import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import * as Yup from "yup";
import moment from "moment";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import DataTable from "Components/DataTable/DataTable";
import EyeIcon from "assets/Icons/EyeIcon";
import axiosInstance from "../../../../../../../../axios";
import {toast} from "react-toastify";
import Select from "react-select";
import DatePickerInput from "../../../../../../../Components/Ui/DatePickerInput/DatePickerInput";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers";
import {useSelector} from "react-redux";
import TrashIcon from "assets/Icons/TrashIcon";
import CustomModal from "../../../../../../../../Components/CustomModal/CustomModal";
import ProductPrices from "../Catalog/Products/ProductPrices";
import SubEventSchedules from "./SubEventSchedules";
import {BASE_URL} from "../../../../../../../../config";

function SubEvents({event, subEvents, fetchSubEvents, galleries}) {
    const {i18n, t} = useTranslation();
    const [subEvent, setSubEvent] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState(undefined);

    const [eventDate, setEventDate] = useState(undefined);

    const [openingTime, setOpeningTime] = useState(undefined);
    const [startTime, setStartTime] = useState(undefined);
    const [endTime, setEndTime] = useState(undefined);

    const [filteredData, setFilteredData] = useState([]);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const userId = useSelector((state) => state.auth.userId);

    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const formatGalleryOptionLabel = ({label, image}) => (
        <div style={{display: "flex"}}>
            <div>{label}</div>
            <img width={48} height={48} src={image} alt={label}/>
        </div>
    );

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "description",
                flex: 1,
            },
            {
                headerName: headerNames.created_at[i18n.language],
                field: "created_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: headerNames.updated_at[i18n.language],
                field: "updated_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setFormValues(params.data)
                                setSubEvent(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    function handleDeleteItem(id) {
        setIsLoading(true);
        axiosInstance.delete(`admin/sub-events/${id}`)
            .then(response => {
                fetchSubEvents();
            })
            .finally(() => {
                setIsLoading(false)
                setShowDeletedModal(false)
            });
    }

    useEffect(() => {
        setFilteredData(subEvents)
    }, [subEvents])

    function getDefaultSubEvent() {
        return {
            event_id: event.id,
            title: undefined,
            summary: undefined,
            description: undefined,
            image: undefined,
            gallery: undefined,
            video: undefined,
            location_link: undefined,
            lat: undefined,
            lng: undefined,
            event_date: undefined,
            active: true,
            opening_time: undefined,
            start_time: undefined,
            finish_time: undefined,
        }
    }

    const [formValues, setFormValues] = useState(getDefaultSubEvent());

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        ),
    });

    function createSubEvent(data) {
        return axiosInstance.post('/admin/sub-events', data)
    }

    function updateSubEvent(data) {
        return axiosInstance.post(`/admin/sub-events/${subEvent.id}`, data)
    }

    function handleSubmit(data) {
        setIsLoading(true);

        const formData = new FormData();

        const fields = {
            ...data,
            event_id: event.id,
            event_date: eventDate ? moment(eventDate).format('YYYY-MM-DD') : undefined,
            opening_time: openingTime,
            start_time: startTime,
            created_by: userId,
            finish_time: endTime,
            store_id: event.store.id,
            active: undefined,
        };

        if (subEvent.id) {
            fields.updated_by = userId
        }

        // formData.append('active', formValues.active ? 1 : 0);

        Object.entries(fields).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (formValues?.image) {
            formData.append('image', formValues.image);
        }

        if (formValues?.video) {
            formData.append('video', formValues.video);
        }

        const action = subEvent.id ? updateSubEvent : createSubEvent;

        action(formData)
            .then(response => {
                setSubEvent(undefined);
                const message = event.id
                    ? (i18n.language === "ar" ? "تم تحديث الكتالوج الحدث الفرعي" : "Sub Event Updated Successfully")
                    : (i18n.language === "ar" ? "تم إنشاء الكتالوج الحدث الفرعي" : "Sub Event Created Successfully");

                toast.success(message);
                fetchSubEvents();
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchSubEvents()
    }, [])

    return (
        <>
            <div className='d-flex justify-content-end'>
                {
                    subEvent && <button
                        onClick={() => setSubEvent(undefined)}
                        className='btn btn-outline-secondary fw-semibold mb-3'>
                        {t('Sub Events list')}
                    </button>
                }

                {
                    !subEvent && <button
                        onClick={() => {
                            setSubEvent(getDefaultSubEvent())
                            setFormValues(getDefaultSubEvent())
                        }}
                        className='btn-main mb-3'>
                        {t('Add a new Sub Event')}
                    </button>
                }
            </div>

            {!subEvent && <DataTable rowData={filteredData} columnDefs={columnDefs}/>}

            {
                subEvent &&
                <>

                    <FormField
                        validationSchema={validationSchema}
                        initialValues={formValues}
                        onSubmit={handleSubmit}
                    >
                        <div className='row'>
                            <div className='col-4'>
                                <InputField label={t('title')} name="title" placeholder="Title"/>
                            </div>

                            <div className='col-4'>
                                <InputField label={t('summary')} name="summary" placeholder="Summary"/>
                            </div>

                            <div className='col-4'>
                                <InputField label={t('location link')} name="location_link"
                                            placeholder="location_link"/>
                            </div>

                            <div className='col-12'>
                                <InputField label={t('description')} name="description" placeholder="Description"/>
                            </div>

                            <div className='col-4'>
                                <label htmlFor='eventDate' className="form-label mt-3">
                                    {t('Event Date')}
                                </label>

                                <DatePickerInput
                                    isLabel={false}
                                    htmlForLabel={"eventDate"}
                                    newClass={"input-date-1"}
                                    idDate={"eventDate"}
                                    selectedDateFunction={eventDate}
                                    OnChangeFunction={(date) => {
                                        setEventDate(date);
                                    }}
                                    dateType={"dd/MM/yyyy"}
                                    placeholderText={"dd/MM/yyyy"}
                                    iconFont={true}
                                    typeIconFont={faCalendarDays}
                                    iconImg={false}
                                    iconImgSrc={false}
                                    altImg={false}
                                />
                            </div>

                            <div className='col-4'>
                                <label htmlFor='formDate1' className="form-label mt-3 d-block">
                                    {t('opening time')}
                                </label>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        className='w-100'
                                        onChange={(e) => {
                                            setOpeningTime(
                                                `${e.$H < 10 ? `${0}${e.$H}` : e.$H}:${
                                                    e.$m < 10 ? `${0}${e.$m}` : e.$m
                                                }`
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className='col-4'>
                                <label htmlFor='formDate1' className="form-label mt-3 d-block">
                                    {t('start time')}
                                </label>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        className='w-100'
                                        onChange={(e) => {
                                            setStartTime(
                                                `${e.$H < 10 ? `${0}${e.$H}` : e.$H}:${
                                                    e.$m < 10 ? `${0}${e.$m}` : e.$m
                                                }`
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='formDate1' className="form-label mt-3 d-block">
                                    {t('end time')}
                                </label>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        className='w-100'
                                        onChange={(e) => {
                                            setEndTime(
                                                `${e.$H < 10 ? `${0}${e.$H}` : e.$H}:${
                                                    e.$m < 10 ? `${0}${e.$m}` : e.$m
                                                }`
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className='col-4'>
                                <label htmlFor="select-gallery" className="mt-3 form-label">
                                    {t("Gallery")}
                                </label>
                                <Select
                                    value={selectedGallery}
                                    formatOptionLabel={formatGalleryOptionLabel}
                                    options={galleries}
                                    id="select-gallery"
                                    placeholder={t("Gallery")}
                                    onChange={(selectedOption) => {
                                        setSelectedGallery(selectedOption)
                                    }}
                                />
                            </div>

                            <div className='col-12 col-lg-6'>
                                <div className="form-group mt-3">
                                    <label className='form-label' htmlFor="image">{t('Upload Image')}</label>
                                    <input
                                        name="image"
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        onChange={(event) => {
                                            setFormValues((prevState) => ({
                                                ...prevState,
                                                image: event.target.files[0],
                                            }));
                                        }}
                                        accept="image/jpeg, image/jpg, image/png, image/gif"
                                    />
                                </div>

                                {
                                    (subEvent.id && subEvent.image) && <img height={300} className='w-100 rounded-3 mt-2' src={BASE_URL + subEvent.image}  />
                                }
                            </div>

                            <div className='col-12 col-lg-6'>
                                <div className="form-group mt-3">
                                    <label className='form-label' htmlFor="video">{t('Upload Video')}</label>
                                    <input
                                        name="video"
                                        type="file"
                                        className="form-control"
                                        id="video"
                                        onChange={(event) => {
                                            setFormValues((prevState) => ({
                                                ...prevState,
                                                video: event.target.files[0],
                                            }));
                                        }}
                                        accept="video/mp4, video/avi, video/mov, video/wmv"
                                    />

                                    {subEvent.video && (
                                        <video height={300} className='w-100 rounded-3 mt-2' controls>
                                            <source src={BASE_URL + subEvent.video} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="d-flex gap-3 align-items-center mt-3">
                                    <input
                                        type="checkbox"
                                        name="active"
                                        checked={formValues.active}
                                        className="form-check-input"
                                        onChange={(e) => setFormValues((prevState) => ({
                                            ...prevState,
                                            active: e.target.checked,
                                        }))}
                                        id="active"
                                    />
                                    <label className="form-check-label" htmlFor="active">
                                        {t('Is Active')}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="btn-main w-100 mt-3"
                        >
                            {t("save")}
                        </button>
                    </FormField>

                    {subEvent.id && <SubEventSchedules subEvent={subEvent}/>}

                </>


            }

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>
        </>
    )
}

export default SubEvents;