import {createSlice} from "@reduxjs/toolkit";
import Cookies from 'js-cookie';

const initialState = {
    isAuthenticated: !!Cookies.get('token'),
    token: Cookies.get('token') || null,
    type: Cookies.get('type') || null,
    userId: Cookies.get('id') || null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.token = action.payload.access_token;
            state.type = action.payload.type;
            Cookies.set('token', action.payload.access_token, {expires: 1 / 24});
            Cookies.set('type', action.payload.type, {expires: 1 / 24});
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.token = null;
            state.type = null;
            state.userId = null;
            Cookies.remove('token');
            Cookies.remove('type');
            Cookies.remove('id');
            // Clear localStorage
            localStorage.removeItem("token");
            localStorage.removeItem("type");
            localStorage.removeItem("userName");
            localStorage.removeItem("userImg");
            localStorage.removeItem("userId");
        },
        setuseId: (state, action) => {
            state.userId = action.payload;
            Cookies.set('id', action.payload, {expires: 1 / 24});
        }
    },
});

export const {login, logout, setuseId} = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;