import NavBar from "../../Components/NavBar/NavBar";
import {Link, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../axios";
import React, {useEffect, useState} from "react";
import {Autoplay, FreeMode, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import './event.css';
import moment from "moment";
import {BASE_URL} from "../../config";
import image from '../../assets/images/event.png'
import Footer from "../../Components/Footer/Footer";
function Event() {
    const {eventId} = useParams();

    const [event, setEvent] = useState(null);
    const [subEvents, setSubEvents] = useState([]);
    const [gallery, setGallery] = useState([]);

    function fetchEvent() {
        return axiosInstance.get('/website/events/' + eventId)
            .then((response) => {
                setEvent(response.data);
                fetchSubEvents(response.data.id)


                // setGallery([]);
                // const galleryIds = response.data.gallery_id ? response.data.gallery_id.split(',') : [];
                // galleryIds.forEach((galleryId) => {
                //     fetchEventGallery(galleryId)
                // })
            })
    }

    function fetchSubEvents(eventId) {
        return axiosInstance.get('/website/sub-events', {
            params: {
                event_id: eventId,
            }
        })
            .then((response) => {
                setSubEvents(response.data);
            })
    }

    function fetchEventGallery(id) {
        return axiosInstance.get('/website/galleries/' + id)
            .then((response) => {
                setGallery(prevState => [...prevState, response.data]);
            })
    }

    useEffect(() => {
        fetchEvent()
    }, []);

    const navigate = useNavigate()
    function redirect(id) {
        navigate('subEvent/' + id)
    }

    return (
        <>
            <div className="event-container content-page">
                <header>
                    <NavBar/>
                </header>

                <main className='container'>

                    {
                        event && <div className='m-3'>
                            <div>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={5}
                                    freeMode={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    grabCursor={true}
                                    modules={[FreeMode, Pagination, Autoplay, Navigation]}
                                    loop={true}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    navigation={false}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        // 768: {
                                        //     slidesPerView: 2,
                                        // },
                                        // 992: {
                                        //     slidesPerView: 3,
                                        // },
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide  className='rounded-2'>
                                        <div className="image-slide">
                                            <img
                                                src={BASE_URL + event.image}
                                                alt="imageSlide"
                                                className="object-fit-md-fill object-fit-cover w-100 rounded-3"
                                                height={"100%"}
                                            />
                                        </div>
                                    </SwiperSlide>
                                    {/*{gallery && gallery.length > 0 ? (*/}
                                    {/*    gallery.map((item) => {*/}
                                    {/*        return (*/}
                                    {/*            <SwiperSlide key={item.id} className='rounded-2'>*/}
                                    {/*                <div className="image-slide">*/}
                                    {/*                    <img*/}
                                    {/*                        src={item.image}*/}
                                    {/*                        alt="imageSlide"*/}
                                    {/*                        className="object-fit-cover w-100 rounded-3"*/}
                                    {/*                        height={"350px"}*/}
                                    {/*                    />*/}
                                    {/*                </div>*/}
                                    {/*            </SwiperSlide>*/}
                                    {/*        );*/}
                                    {/*    })*/}
                                    {/*) : (*/}
                                    {/*    <SwiperSlide className='rounded-2'>*/}
                                    {/*        <div className="placeholder placeholder-wave text-center w-100">*/}
                                    {/*            <div*/}
                                    {/*                className="d-flex align-items-center justify-content-center rounded-3"*/}
                                    {/*                style={{height: "350px"}}>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </SwiperSlide>*/}
                                    {/*)}*/}
                                </Swiper>
                            </div>

                            <p className='mt-2 fw-semibold' style={{fontSize: "20px"}}>{event.title}</p>

                            <p className='mt-2 event-description' style={{fontSize: "20px"}}>{event.description}</p>

                            <div className='mt-3'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
                                        stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path
                                        d="M15.7109 15.1798L12.6109 13.3298C12.0709 13.0098 11.6309 12.2398 11.6309 11.6098V7.50977"
                                        stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <span className='mx-2 event-description fw-semibold' style={{fontSize: "18px"}}>
                                    {moment(event.date).format('D MMMM YYYY')}  -  {moment(event.date_to).format('D MMMM YYYY')}
                                </span>
                            </div>

                            <div className='mt-3'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.0009 13.4304C13.724 13.4304 15.1209 12.0336 15.1209 10.3104C15.1209 8.5873 13.724 7.19043 12.0009 7.19043C10.2777 7.19043 8.88086 8.5873 8.88086 10.3104C8.88086 12.0336 10.2777 13.4304 12.0009 13.4304Z"
                                        stroke="#2F65CC" stroke-width="1.5"/>
                                    <path
                                        d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z"
                                        stroke="#2F65CC" stroke-width="1.5"/>
                                </svg>

                                <span className='mx-2 event-description fw-semibold' style={{color: '#2F65CC', fontSize: "18px"}}>
                                    {event.address}
                                </span>
                            </div>

                            <div className='border-bottom my-3'></div>

                            <div>
                                {
                                    subEvents.map((item) => {
                                        return (
                                            <div
                                                onClick={() => redirect(item.id)}
                                                key={item.id}
                                                className="sub-event-card">
                                                <img className="sub-event-card-img" src={BASE_URL + item.image}
                                                     alt="Card Image" height={"100%"}/>
                                                <div className="sub-event-card-content d-flex justify-content-between">
                                                    <div className="sub-event-card-text">{item.title}</div>
                                                    <button className="ticket-btn rounded-pill">
                                                        <svg width="20"
                                                             height="20"
                                                             viewBox="0 0 20 20"
                                                             className='me-2'
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M16.2493 10.4163C16.2493 9.26634 17.1827 8.33301 18.3327 8.33301V7.49967C18.3327 4.16634 17.4993 3.33301 14.166 3.33301H5.83268C2.49935 3.33301 1.66602 4.16634 1.66602 7.49967V7.91634C2.81602 7.91634 3.74935 8.84967 3.74935 9.99967C3.74935 11.1497 2.81602 12.083 1.66602 12.083V12.4997C1.66602 15.833 2.49935 16.6663 5.83268 16.6663H14.166C17.4993 16.6663 18.3327 15.833 18.3327 12.4997C17.1827 12.4997 16.2493 11.5663 16.2493 10.4163Z"
                                                                stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round"/>
                                                            <path d="M8.33398 3.33301L8.33399 16.6663" stroke="white"
                                                                  stroke-width="1.5" stroke-linecap="round"
                                                                  stroke-linejoin="round" stroke-dasharray="5 5"/>
                                                        </svg>

                                                        شِراء تذكرة
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    }
                </main>

                <Footer />
            </div>
        </>
    )
}

export default Event;