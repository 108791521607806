import axiosInstance from "../../../axios";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import DataTable from "../../../Components/DataTable/DataTable";
import moment from "moment";
import EyeIcon from "assets/Icons/EyeIcon";

function Competitions() {
    const [competitions, setCompetitions] = useState([]);
    const [participants, setParticipants] = useState([]);

    const [selectedCompetition, setSelectedCompetition] = useState(null);

    const {t, i18n} = useTranslation();

    const competitionHeaderNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        summary: {
            en: "Summary",
            ar: "الملخص",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        date_to: {
            en: "Event Date",
            ar: "تاريخ الحدث",
        },
        price: {
            en: "Price",
            ar: "السعر",
        },
        places: {
            en: "Available Places",
            ar: "الأماكن المتاحة",
        },
        status: {
            en: "Status",
            ar: "الحالة",
        },
        location_link: {
            en: "Location Link",
            ar: "رابط الموقع",
        },
        image: {
            en: "Image",
            ar: "الصورة",
        },
    };

    const competitionColumnDefs = useMemo(
        () => [
            {
                headerName: competitionHeaderNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: competitionHeaderNames.summary[i18n.language],
                field: "summary",
                flex: 1,
            },
            {
                headerName: competitionHeaderNames.description[i18n.language],
                field: "description",
                flex: 1,
            },
            {
                headerName: competitionHeaderNames.date_to[i18n.language],
                field: "date_to",
                flex: 0.5,
                cellRenderer: (params) => (
                    <span>{moment(params.data.date_to).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: competitionHeaderNames.price[i18n.language],
                field: "price",
                flex: 0.5,
                cellRenderer: (params) => (
                    <span dir='ltr'>{params.data.price} OMR</span>
                ),
            },
            {
                headerName: competitionHeaderNames.places[i18n.language],
                field: "places",
                flex: 0.5,
            },
            {
                headerName: competitionHeaderNames.status[i18n.language],
                field: "status",
                flex: 0.5,
                cellRenderer: (params) => (
                    <span>{params.data.status === 1 ? "Active" : "Inactive"}</span>
                ),
            }, {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setSelectedCompetition(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>
                    </>
                ),
            },
        ],
        [i18n.language]
    );

    const participantsHeaderNames = {
        name: {
            en: "Name",
            ar: "الاسم",
        },
        email: {
            en: "Email",
            ar: "البريد الإلكتروني",
        },
        phone: {
            en: "Phone",
            ar: "الهاتف",
        },
        status: {
            en: "Status",
            ar: "الحالة",
        },
        address: {
            en: "Address",
            ar: "العنوان",
        },
        country_code: {
            en: "Country Code",
            ar: "رمز البلد",
        },
        verified: {
            en: "Verified",
            ar: "تم التحقق",
        },
        qr: {
            en: "QR Code",
            ar: "رمز الاستجابة السريعة",
        },
        country_id: {
            en: "Country",
            ar: "الدولة",
        },
        customer_id: {
            en: "Customer",
            ar: "العميل",
        },
        competition_id: {
            en: "Competition",
            ar: "المسابقة",
        },
        image: {
            en: "Image",
            ar: "الصورة",
        },
    };

    const participantsColumnDefs = useMemo(
        () => [
            {
                headerName: participantsHeaderNames.name[i18n.language],
                field: "name",
                flex: 0.5,
            },
            {
                headerName: participantsHeaderNames.email[i18n.language],
                field: "email",
                flex: 1,
            },
            {
                headerName: participantsHeaderNames.phone[i18n.language],
                field: "phone",
                flex: 1,
            },
            {
                headerName: participantsHeaderNames.status[i18n.language],
                field: "status",
                flex: 0.5,
                cellRenderer: (params) => (
                    <span>{params.data.status === 1 ? "Active" : "Inactive"}</span>
                ),
            },
            {
                headerName: participantsHeaderNames.address[i18n.language],
                field: "address",
                flex: 1,
            },
            {
                headerName: participantsHeaderNames.country_code[i18n.language],
                field: "country_code",
                flex: 0.5,
            },
            {
                headerName: participantsHeaderNames.verified[i18n.language],
                field: "verified",
                flex: 0.5,
                cellRenderer: (params) => (
                    <span>{params.data.verified === 1 ? "Yes" : "No"}</span>
                ),
            },

            {
                headerName: participantsHeaderNames.country_id[i18n.language],
                field: "country_id",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{params.data.country ? params.data.country.name : ""}</span>
                ),
            },
            {
                headerName: participantsHeaderNames.customer_id[i18n.language],
                field: "customer_id",
                flex: 1,
                // Assuming you want to display customer name if it's a foreign key
                cellRenderer: (params) => (
                    <span>{params.data.customer ? params.data.customer.name : ""}</span>
                ),
            }
        ],
        [i18n.language]
    );


    function fetchCompetitions() {
        return axiosInstance.get('/admin/competitions')
            .then((response) => {
                setCompetitions(response.data);
            })
    }

    function fetchParticipants() {
        return axiosInstance.get('/admin/participants', {
            params: {
                competition_id: selectedCompetition.id
            }
        })
            .then((response) => {
                setParticipants(response.data);
            })
    }

    useEffect(() => {
        fetchCompetitions();
    }, [])

    useEffect(() => {
        if (selectedCompetition) {
            fetchParticipants();
        }
    }, [selectedCompetition])

    return (
        <div className='mt-3'>
            {
                selectedCompetition ?
                    <div>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                            <p className={'fw-semibold'}>{t('competition name')}: {selectedCompetition.title}</p>

                            <button className='btn btn-outline-secondary' onClick={() => setSelectedCompetition(null)}>{t('back to list')}</button>
                        </div>

                        <DataTable rowData={participants} columnDefs={participantsColumnDefs}/>
                    </div>
                    :
                    <DataTable rowData={competitions} columnDefs={competitionColumnDefs}/>
            }
        </div>
    )
}

export default Competitions;