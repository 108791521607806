import React from "react";
import {Col, Row} from 'react-bootstrap';

// Css
import './OrderCard.css';

// Assets
import {useTranslation} from "react-i18next";
import {BASE_URL} from "config";

function OrderCard({order}) {
    const {t} = useTranslation();
    return (
        <Row className='order-card-container py-3 px-3 px-md-0 gx-0 gx-md-4'>
            <Col xs={8} md={8} className='d-flex flex-column justify-content-between'>
                <div>
                    <p className='order-card-title mb-2'>{order.ticket?.title || order.product.title}</p>
                </div>

                <div>
                    <p className='order-card-final-price mb-2'>{order.final_price} {t('OMR')} </p>
                </div>
            </Col>

            <Col xs={4} md={4} className='d-flex flex-column align-items-center justify-content-between gap-2'>
                <div>
                    {
                        (order.ticket?.image || order.product.image) ?
                            <img width={100} src={BASE_URL + (order.ticket?.image || order.product.image)}
                                 alt={order.title}/> :
                            <div style={{width: 100, height: 100}} className={'bg-light rounded-2'}></div>
                    }
                </div>
                <div>
                    <p className='order-card-title mb-2'>{order.quantity}</p>
                </div>
            </Col>
        </Row>
    );
}

export default OrderCard;
