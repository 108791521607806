import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

// Axios
import axiosInstance from "../../../../../axios";

function CatalogBarcodeReader() {
    const {barcode} = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        function fetchCatalog() {
            return axiosInstance.get(`website/catalogs/barcode/${barcode}`)
                .then(response => {
                    navigate(`/store/${response.data.store_id}/catalog`);
                })
        }

        if (barcode) {
            fetchCatalog()
        }
    }, [barcode]);
}

export default CatalogBarcodeReader;