import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import InputField from "Components/Forms/InputField";
import FormField from "Components/Forms/FormFiled";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import axiosInstance from "../../../../../../../../axios";
import {toast} from "react-toastify";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import DatePickerInput from "../../../../../../../Components/Ui/DatePickerInput/DatePickerInput";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers";
import {useSelector} from "react-redux";
import moment from "moment";
import './Event.css'
import {useParams} from "react-router-dom";
import OrderCard from "../OrdersData/OrderCard/OrderCard";
import OrderList from "Pages/Order/OrderList";
function ConfirmTicket() {
    const {barcode} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState(undefined)
    const [orderItems, setOrderItems] = useState([])
    const {i18n} = useTranslation()
    function fetchTicketOrder() {
        setIsLoading(true);

        return axiosInstance.get(`/website/orders/barcode/${barcode}`)
            .then(response => {
                setOrder(response.data);
            })
            .finally(() => setIsLoading(false));
    }

    function fetchOrderItems() {
        setIsLoading(true);
        const params = {
            order_id: order?.id
        }

        return axiosInstance.get(`/website/order-items/barcode/${barcode}`)
            .then((response) => {
                setOrderItems(response.data);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            })
            .finally(() => setIsLoading(false));
    }

    function handleSubmit() {
        axiosInstance.post(`/admin/orders/change-status/${order.id}`, {
      
                status: 'delivered'
  
        }).then(() => {
            // fetchOrderItems()
            fetchTicketOrder()
        })
    }

    useEffect(() => {
        fetchTicketOrder();
    }, []);
    
    useEffect(() => {
        if (order?.id) {
            fetchOrderItems();
        }
    }, [order]); // زمانی که مقدار order تغییر کند، fetchOrderItems اجرا می‌شود
    

    return (
        (!order || isLoading) ? <>
            <div className={'col-12 col-lg-4 mt-0 d-flex align-items-center justify-content-between rounded-2 mt-3 border gap-3 p-3 bg-white '}>loading</div>
        </> : <>
        
            <div >
                <OrderList orders={orderItems}/>
            </div>
            
            <div>
                <div>


                    <div
                        className='col-12 col-lg-4 mt-0 d-flex align-items-center justify-content-between rounded-2 mt-3 border gap-3 p-3 bg-white'>
                        <button
                            onClick={handleSubmit}
                            disabled={order.status == "paid" ? false:true}                            
                            className={'w-100 btn btn-primary'}

                        >
                            {i18n.language === 'ar' ? 'تسليم التذكرة' : 'Deliver Ticket'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmTicket;
