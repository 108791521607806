import {useTranslation} from "react-i18next";
import TabsContentTb from "../../../../../../../Shared/TabsContentTb/TabsContentTb";
import {Tab} from "react-bootstrap";
import axiosInstance from "../../../../../../../../axios";
import {useEffect, useState} from "react";
import Event from "./Event";
import SubEvents from "./SubEvent";
import Activities from "./Activities";
import Tickets from "./Tickets";
import EventOrders from "./EventOrders";
import SaleReport from "./SaleReport";

function TabsEvents({event, setEvent, store, fetchEvents}) {
    const {i18n, t} = useTranslation();
    const [subEvents, setSubEvents] = useState([]);
    const [galleries, setGalleries] = useState([]);

    function fetchSubEvents() {
        const params = {
            event_id: event.id,
        }
        return axiosInstance.get("/admin/sub-events", {
            params,
        })
            .then(response => {
                setSubEvents(response.data)
            })
    }


    function fetchGalleries() {
        const params = {
            store_id: store.id
        }

        return axiosInstance.get('/admin/galleries', {
            params,
            headers: {
                "Accept-language": i18n.language,
            }
        })
            .then(response => {
                setGalleries(response.data.map(gallery => {
                    return {
                        ...gallery,
                        value: gallery.id
                    }
                }));
            })
    }

    useEffect(() => {
       fetchGalleries();
    },[])

    const tabInfo = [
        {
            eventKey: "event",
            title: i18n.language === "ar" ? "حدث" : "event",
            tabInfo: <Event
                setEvent={setEvent}
                catalog={event}
                galleries={galleries}
                store={store}
                fetchEvents={fetchEvents}
            />,
            disabled: false
        },
        {
            eventKey: "subEvent",
            title: i18n.language === "ar" ? "حدث فرعي" : "sub event",
            tabInfo: <SubEvents
                setEvent={setEvent}
                event={event}
                subEvents={subEvents}
                galleries={galleries}
                fetchSubEvents={fetchSubEvents}
            />,
            disabled: !event.id
        },
        {
            eventKey: "Ticket",
            title: i18n.language === "ar" ? "تذكرة" : "Ticket",
            tabInfo: <Tickets
                subEvents={subEvents}
                event={event}
            />,
            disabled: !event.id
        },
        {
            eventKey: "activity",
            title: i18n.language === "ar" ? "نشاط" : "activity",
            tabInfo: <Activities event={event}/>,
            disabled: !event.id
        },
        {
            eventKey: "orders",
            title: i18n.language === "ar" ? "الطلبات" : "orders",
            tabInfo: <EventOrders store={store} event={event}/>,
            disabled: !event.id
        },
        {
            eventKey: "reports",
            title: i18n.language === "ar" ? "التقارير" : "reports",
            tabInfo: <SaleReport event={event}/>,
            disabled: !event.id
        },
    ];

    return (
        <>
            <button
                onClick={() => setEvent(undefined)}
                className='btn btn-outline-secondary fw-semibold'
            >
                {t('Event list')}
            </button>

            <div className="all-tabs-items tabs-dashboard-control  margin-top position-relative">
                <TabsContentTb tabActive={"event"}>
                    {tabInfo.map((item) => {
                        return (
                            <Tab
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                title={item.title}
                                disabled={item.disabled}
                                tabClassName={item.disabled && 'opacity-50'}
                            >
                                {item.tabInfo}
                            </Tab>
                        );
                    })}
                </TabsContentTb>
            </div>

        </>
    );

}

export default TabsEvents;