import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

// Redux
import {useSelector} from 'react-redux';

// Assets
import DiscountIcon from "../../../../../assets/Icons/DiscountIcon";

function CatalogStoreOrderFactor() {
    const {selectedProducts, fixedPrices, totalPrice} = useSelector((state) => state.catalog);

    const {t} = useTranslation();

    const totalDiscount = useMemo(() => {
        return selectedProducts.reduce((total, product) => {
            const quantity = product.quantity || product._count;

            const discountPerProduct = product.initial_price - product.final_price;
            total += discountPerProduct * quantity;

            return total;
        }, 0);
    }, [selectedProducts]);

    const totalProductPrice = useMemo(() => selectedProducts.reduce((total, product) => {
        const quantity = product.quantity || product._count;

        if (product.activePrice && product.activePrice.final_price && quantity) {
            total += product.activePrice.final_price * quantity;
        }
        return total;
    }, 0), [selectedProducts]);

    return (
        <div className='catalog-store-order-factor fw-semibold rounded-2'>
            <div>
                <p className='fs-6 fw-semibold px-3 py-2'>{t('your purchase invoice')}</p>

                <div className='rounded-2 overflow-hidden'>
                    <div className='d-flex align-items-center justify-content-between bg-light px-3 py-2'>
                        <p>{t("total cost")}</p>

                        <p className='fw-medium'>{parseFloat(totalProductPrice.toFixed(2))} {t("Riyal")}</p>
                    </div>

                    {
                        fixedPrices.map(item => (
                            item.is_active ? (
                                <div className='bg-white px-3 py-2 border-bottom' key={item.id}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p>{item.title}</p>
                                        <p className='fw-medium'>{item.amount} {item.amount_type === "percentage" ? t("percentage") : t("Riyal")}</p>
                                    </div>

                                    <p className='catalog-store-description my-2'>{item.description}</p>
                                </div>
                            ) : null
                        ))
                    }

                    {
                        totalDiscount ? <div
                            className='catalog-store-order-discount rounded-2 d-flex justify-content-between align-items-center'>
                            <p>{t('your discount amount')}</p>

                            <div
                                className='d-flex align-items-center gap-1 px-2 py-1 rounded-2 bg-primary text-white fs-6'>
                                <DiscountIcon/>

                                <div>
                                    {totalDiscount} {t('OMR discount')}
                                </div>
                            </div>
                        </div> : null

                    }

                    <div>
                        <div
                            className='catalog-store-total-price d-flex align-items-center justify-content-between bg-light px-3 py-2'>
                            <p>{t('total final amount')}</p>

                            <p className='fw-medium'>{totalPrice && parseFloat(totalPrice.toFixed(2))} {t('OMR')}</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default CatalogStoreOrderFactor;
