import {useState} from "react";
import NavBar from "../Components/NavBar/NavBar";
import Header from "../Components/Header/Header";
import Category from "../Components/Category/Category";
import Banner from "../Components/Banner/Banner";
import Cards from "../Components/Cards/Cards";
import BannerJoin from "../Components/Banner/BannerJoin";
import JoinUs from "../Components/JoinUs/JoinUs";
import OuroTop from "Components/OuroTop/OuroTop";
import Statistics from "Components/Statistics/Statistics";
import Contact from "Components/Contact/Contact";
import Footer from "Components/Footer/Footer";
import DownloadApp from "Components/DownloadApp/DownloadApp";

const Home = () => {
    const [showDownloadApp, setShowDownloadApp] = useState(false);
    const hideDownloadApp = () => {
        setShowDownloadApp(false);
    };
    const showModalDownloadApp = (e) => {
        e.preventDefault();
        setShowDownloadApp(true);
    };

    return (
        <div className="content-page">
            <DownloadApp
                showDownloadApp={showDownloadApp}
                hideDownloadApp={hideDownloadApp}
            />
            <header>
                <NavBar showModalDownloadApp={showModalDownloadApp}/>
                <Header showModalDownloadApp={showModalDownloadApp}/>
            </header>
            <div className="container">
                <main>
                <div className="img-front" 
                style={{borderRadius: "10px" , marginBottom: "40px"}}
                >
                    <a href="/event/1/subEvent/1" target="_blank" rel="noopener noreferrer">
                        <img src="https://dashboard.ourocards.com/event/120/a17wIa4S1dDEwl2j4j3d.png" height="500" className="object-fit-cover w-100 card-img-top" alt="img card" style={{borderRadius: "10px"}}></img>
                    </a>
                </div>
                    <Category/>


                    <OuroTop showModalDownloadApp={showModalDownloadApp}/>
                    {/* <Banner /> */}
                    <Cards showModalDownloadApp={showModalDownloadApp}/>
                    <JoinUs/>
                    <BannerJoin showModalDownloadApp={showModalDownloadApp}/>
                    <Statistics/>
                </main>
            </div>
            <Contact/>
            <Footer/>
        </div>
    );
};

export default Home;
