import NavBar from "../../../Components/NavBar/NavBar";
import {Link, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../axios";
import React, {useEffect, useState} from "react";
import {Autoplay, FreeMode, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import moment from "moment";
import {BASE_URL} from "../../../config";
import './subEvent.css'
import Footer from "../../../Components/Footer/Footer";

function SubEvent() {
    const {subEventId, eventId} = useParams();

    const [subEvent, setSubEvent] = useState(null);
    const [subEventSchedules, setSubEventSchedules] = useState([]);
    const [event, setEvent] = useState(null);
    const [tickets, setTickets] = useState( []);
    const [activities, setActivities] = useState([]);

    function isPassed(date) {
        const eventDate = moment(date, "YYYY-MM-DD");
        const currentDate = moment();
        return eventDate.isBefore(currentDate, "day");
    }

    function fetchSubEvent() {
        return axiosInstance.get('/website/sub-events/' + subEventId)
            .then((response) => {
                setSubEvent(response.data.data);

                // setGallery([]);
                // const galleryIds = response.data.gallery_id ? response.data.gallery_id.split(',') : [];
                // galleryIds.forEach((galleryId) => {
                //     fetchEventGallery(galleryId)
                // })
            })
    }

    function fetchTickets() {
        axiosInstance.get('/website/tickets', {
            params: {
                event_id: eventId,
                sub_event_id: subEventId,
            }
        })
            .then((response) => {
                setTickets(response.data.data);
            })
    }

    function fetchSubEventSchedules() {
        axiosInstance.get('/website/sub-event-schedules', {
            params: {
                sub_event_id: subEventId,
            }
        })
            .then((response) => {
                setSubEventSchedules(response.data);
            })
    }

    function fetchEvent() {
        return axiosInstance.get('/website/events/' + eventId)
            .then((response) => {
                setEvent(response.data);

                // setGallery([]);
                // const galleryIds = response.data.gallery_id ? response.data.gallery_id.split(',') : [];
                // galleryIds.forEach((galleryId) => {
                //     fetchEventGallery(galleryId)
                // })
            })
    }

    function fetchActivities() {
        return axiosInstance.get('/website/activities', {
            params: {
                event_id: eventId,
            }
        })
        .then((response) => {
            setActivities(response.data);
        })
    }

    const navigate = useNavigate();

    useEffect(() => {
        fetchSubEvent()
        fetchEvent()
        fetchSubEventSchedules()
        fetchActivities()
        fetchTickets()
    }, []);

    return (
        <>
            <div className="event-container content-page">
                <header>
                    <NavBar/>
                </header>

                <main className='container'>
                    {
                        (subEvent && event) &&
                        <div className={'pb-5 mb-5'}>

                            <div>
                                <div
                                    className={'ms-3 z-2  position-absolute top-0 start-0 py-3 d-flex align-items-center justify-content-between'}>
                                    <button onClick={() => navigate(-1)}
                                            className={'btn border border-white border-2 rounded-circle py-2'}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.91016 4.07992L15.4302 10.5999C16.2002 11.3699 16.2002 12.6299 15.4302 13.3999L8.91016 19.9199"
                                                stroke="white" stroke-width="2" stroke-miterlimit="10"
                                                stroke-linecap="round" stroke-linejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={5}
                                    freeMode={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    grabCursor={true}
                                    modules={[FreeMode, Pagination, Autoplay, Navigation]}
                                    loop={true}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    navigation={false}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        // 768: {
                                        //     slidesPerView: 2,
                                        // },
                                        // 992: {
                                        //     slidesPerView: 3,
                                        // },
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide className='rounded-2'>
                                        <div className="sub-event-card-image">
                                            <img className="sub-event-card-image-img" src={BASE_URL + subEvent.image}
                                                 alt="Event Image"/>
                                            <div className="sub-event-card-image-content">
                                                <div className="sub-event-card-image-text">
                                                    <div className="event-name mb-1">{subEvent.title}</div>
                                                    <div className="event-location">{subEvent.title} . مسقط</div>
                                                </div>
                                                <div className="event-timer">
                                                    <div id="countdown"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    {/*{gallery && gallery.length > 0 ? (*/}
                                    {/*    gallery.map((item) => {*/}
                                    {/*        return (*/}
                                    {/*            <SwiperSlide key={item.id} className='rounded-2'>*/}
                                    {/*                <div className="image-slide">*/}
                                    {/*                    <img*/}
                                    {/*                        src={item.image}*/}
                                    {/*                        alt="imageSlide"*/}
                                    {/*                        className="object-fit-cover w-100 rounded-3"*/}
                                    {/*                        height={"350px"}*/}
                                    {/*                    />*/}
                                    {/*                </div>*/}
                                    {/*            </SwiperSlide>*/}
                                    {/*        );*/}
                                    {/*    })*/}
                                    {/*) : (*/}
                                    {/*    <SwiperSlide className='rounded-2'>*/}
                                    {/*        <div className="placeholder placeholder-wave text-center w-100">*/}
                                    {/*            <div*/}
                                    {/*                className="d-flex align-items-center justify-content-center rounded-3"*/}
                                    {/*                style={{height: "350px"}}>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </SwiperSlide>*/}
                                    {/*)}*/}
                                </Swiper>
                            </div>

                            <div className='m-3'>
                                <p className='mt-2 fw-semibold' style={{fontSize: "20px"}}>{event.title}</p>

                                <p className='mt-2 event-description' style={{fontSize: "20px", fontStyle: "bold"}}>{event.description}</p>

                                <div className='border-bottom my-3'></div>

                                <div style={{fontSize: 12}}
                                     className={'d-flex align-items-center mb-2 gap-2 fw-semibold'}>

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3.5 9.08984H20.5" stroke="#292D32" stroke-width="1.5"
                                              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path
                                            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                                            stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6937 13.7002H15.7027" stroke="#292D32" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6937 16.7002H15.7027" stroke="#292D32" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9945 13.7002H12.0035" stroke="#292D32" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9945 16.7002H12.0035" stroke="#292D32" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29529 13.7002H8.30427" stroke="#292D32" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29529 16.7002H8.30427" stroke="#292D32" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <p style={{fontSize: "18px"}}>الأيام التي لدينا تذاكر لشرائها</p>
                                </div>


                                <div className='row'>
                                    {tickets.map((ticket, index) => (
                                        <div key={index} className="col-4">
                                            <div
                                                className={`ticket-card ${isPassed(ticket.date) ? "ticket-past" : "ticket-active"} card`}>
                                                <div className="card-body text-center">
                                                    <div className="ticket-date text-nowrap" style={{fontSize: "12px"}}>
                                                        {ticket.title}
                                                    </div>

                                                    <div
                                                        className={`btn ticket-count rounded-pill text-nowrap ${isPassed(ticket.date) ? "btn-secondary" : "btn-primary"}`}>
                                                        {ticket.quantity === 0 ? "Over" : `${ticket.quantity} تذكرة`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div style={{fontSize: 12}} className='d-flex align-items-center fw-semibold gap-2'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
                                            stroke="#121212" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path
                                            d="M15.7109 15.1798L12.6109 13.3298C12.0709 13.0098 11.6309 12.2398 11.6309 11.6098V7.50977"
                                            stroke="#121212" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>

                                    <p style={{fontSize: "18px"}}> تُفتح الأبواب في الساعة {subEvent.opening_time}</p>

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
                                            stroke="#121212" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path
                                            d="M15.7109 15.1798L12.6109 13.3298C12.0709 13.0098 11.6309 12.2398 11.6309 11.6098V7.50977"
                                            stroke="#121212" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    </svg>
                                    <p style={{fontSize: "18px"}}> يبدأ العرض في الساعة {subEvent.start_time}</p>
                                </div>



                                <Link to={'ticket'} className='btn btn-primary w-100 mt-3'>إحجز التذكرة</Link>

                                {/* <div style={{fontSize: 18}}
                                     className={'d-flex align-items-center my-2 gap-2 fw-semibold'}>
                                    <p>جدول أداء الفنانين</p>
                                </div>

                                <div className='d-flex gap-2 align-items-center overflow-auto'>
                                    {
                                        subEventSchedules.map((schedule, index) => (
                                            <div key={index} className="col-6 d-flex gap-3 align-items-center">
                                                <div>
                                                    <img height={60} width={60} className={'rounded-3'}
                                                         src={BASE_URL + schedule.image} alt=""/>
                                                </div>

                                                <div className={'d-flex flex-column gap-1'}>
                                                    <p className={'fs-6 fw-semibold'}>{schedule.title}</p>
                                                    <p className="d-flex gap-1 align-items-center">
                                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M8.33447 3.39547C8.65494 2.90525 8.91602 2.36761 8.91602 1.91699C8.91602 0.750326 8.13251 0.166992 7.16602 0.166992C6.19952 0.166992 5.41602 0.750326 5.41602 2.03899C5.41602 2.50695 5.64679 3.00358 5.94073 3.44634C6.51959 4.31827 7.76179 4.27148 8.33447 3.39547ZM7.16602 2.50033C7.48818 2.50033 7.74935 2.23916 7.74935 1.91699C7.74935 1.59483 7.48818 1.33366 7.16602 1.33366C6.84385 1.33366 6.58268 1.59483 6.58268 1.91699C6.58268 2.23916 6.84385 2.50033 7.16602 2.50033ZM2.49935 11.8337C3.66602 11.8337 4.83268 9.62232 4.83268 8.33366C4.83268 7.04499 3.78801 6.00033 2.49935 6.00033C1.21068 6.00033 0.166016 7.04499 0.166016 8.33366C0.166016 9.62232 1.33268 11.8337 2.49935 11.8337ZM2.49935 9.20866C2.9826 9.20866 3.37435 8.81691 3.37435 8.33366C3.37435 7.85041 2.9826 7.45866 2.49935 7.45866C2.0161 7.45866 1.62435 7.85041 1.62435 8.33366C1.62435 8.81691 2.0161 9.20866 2.49935 9.20866ZM7.60352 5.41699C7.60352 5.17537 7.40764 4.97949 7.16602 4.97949C6.92439 4.97949 6.72852 5.17537 6.72852 5.41699V6.58366C6.72852 7.79178 7.70789 8.77116 8.91602 8.77116H9.49935C10.2242 8.77116 10.8118 9.35878 10.8118 10.0837C10.8118 10.8085 10.2242 11.3962 9.49935 11.3962H4.83268C4.59106 11.3962 4.39518 11.592 4.39518 11.8337C4.39518 12.0753 4.59106 12.2712 4.83268 12.2712H9.49935C10.7075 12.2712 11.6868 11.2918 11.6868 10.0837C11.6868 8.87554 10.7075 7.89616 9.49935 7.89616H8.91602C8.19114 7.89616 7.60352 7.30853 7.60352 6.58366V5.41699Z"
                                                                  fill="#28303F"/>
                                                        </svg>

                                                        <span className='text-muted'>{schedule.description}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div> */}

                                <div style={{fontSize: 18}}
                                     className={'d-flex align-items-center mt-3 mb-2 gap-2 fw-semibold'}>
                                    <p>أهم الأنشطة المجانية</p>
                                </div>

                                <div className='d-flex gap-2 align-items-center overflow-auto'>
                                    {
                                        activities.filter(activity => activity.type === 'FreeActivity').map((activity, index) => (
                                            <div key={index}
                                                 className="col-6 col-md-4 d-flex flex-column rounded-3 border overflow-hidden">
                                                <div>
                                                    <img  className={'w-100'}
                                                         src={BASE_URL + activity.image} alt=""/>
                                                </div>

                                                <p className={'fs-6 fw-semibold px-3 py-2'}>{activity.title}</p>

                                            </div>
                                        ))
                                    }
                                </div>

                                <div style={{fontSize: 18}}
                                     className={'d-flex align-items-center mt-3 mb-2 gap-2 fw-semibold'}>
                                    <p>أهم الأنشطة المدفوعة</p>
                                </div>

                                <div className='d-flex gap-2 align-items-center overflow-auto'>
                                    {
                                        activities.filter(activity => activity.type === 'PaidActivity').map((activity, index) => (
                                            <div key={index}
                                                 className="col-6 col-md-4 d-flex flex-column rounded-3 border overflow-hidden">
                                                <div>
                                                    <img  className={'w-100'}
                                                         src={BASE_URL + activity.image} alt=""/>
                                                </div>

                                                <p className={'fs-6 fw-semibold px-3 py-2'}>{activity.title}</p>

                                            </div>
                                        ))
                                    }
                                </div>

                            </div>

                            <div className='event-action'>
                                <div className={'container mx-3 d-flex align-items-center justify-content-between'}>
                                    <Link to={'ticket'} className='btn btn-primary w-100 mt-3'>إحجز التذكرة</Link>
                                </div>
                            </div>
                        </div>

                    }
                </main>

                <Footer />

            </div>
        </>
    )
}

export default SubEvent;