import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axiosInstance from "../../../../../../../../axios";
import Loading from "../../../../../../../Components/LottieFiles/Loading";
import OrderCard from "./OrderCard/OrderCard";

const OrderDetails = () => {
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const [order, setOrder] = useState(null);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);

    function fetchOrder() {
        setLoading(true);
        return axiosInstance.get(`/admin/orders/${id}`, {
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then((response) => {
                setOrder(response.data.data);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            })
            .finally(() => setLoading(false));
    }

    function fetchOrderItems() {
        setLoading(true);
        const params = {
            order_id: id
        }
        return axiosInstance.get(`/admin/order-items`, {
            params,
            headers: {
                "Accept-Language": i18n.language,
            },
        })
            .then((response) => {
                setOrders(response.data);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchOrder()
        fetchOrderItems()
    }, []);

    return (
        <>
            {(loading || !order) ?
                <div className="w-100 bg-white">
                    <Loading/>
                </div>
                :
                <div className='row g-3 mt-2'>
                    <div className='col-4 d-flex flex-column gap-2 mt-0'>
                        <div className="card col p-2 d-flex justify-content-between align-items-center flex-row">
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center gap-1 mb-2'>
                                    <p className='text-muted'>{t('Customer Name')} : </p>

                                    <p className='fs-6 fw-medium'>{order.customer ? order.customer.name : order.customer_name}</p>
                                </div>

                                <div className='d-flex align-items-center gap-1'>
                                    <p className='text-muted'>{t('customer Phone')}: </p>

                                    <p className='fs-6 fw-medium'>{order.customer ? order.customer.phone : order.customer_phone}</p>
                                </div>
                            </div>

                            {
                                order.customer &&
                                <Link
                                    to={`/dashboardAdmin/clientFile/${order.customer.id}`}
                                    className='rounded-2 overflow-hidden'
                                >
                                    <img
                                        width={48}
                                        height={48}
                                        src={order.customer ? order.customer.image : ''}
                                        alt={order.customer ? order.customer.name : order.customer_name}
                                    />
                                </Link>
                            }
                        </div>

                        <div className="card col p-2 d-flex justify-content-between align-items-center flex-row">
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center gap-1 mb-2'>
                                    <p className='text-muted'>{t('Store Name')}: </p>

                                    <p className='fs-6 fw-medium'>{order.store.title}</p>
                                </div>

                                <div className='d-flex align-items-center gap-1'>
                                    <p className='text-muted'>{t('Branch Address')} : </p>

                                    <p className='fs-6 fw-medium'>{order.branch?.address}</p>
                                </div>
                            </div>

                            <Link
                                to={`/dashboardAdmin/storeInformation/${order.store.id}`}
                                className='rounded-2 overflow-hidden'
                            >

                                <img
                                    width={48}
                                    height={48}
                                    src={order.store.image}
                                    alt={order.store.id}
                                />
                            </Link>
                        </div>

                        <div className="card col p-2 d-flex justify-content-between align-items-center flex-row">
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center gap-1 mb-2'>
                                    <p className='text-muted'>{t('price')}: </p>

                                    <p className='fs-6 fw-medium'>{order.price}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-8 mt-0'>
                        <div className="pb-3 row flex-row">
                            {
                                orders.map((order) => (
                                    <div className='col-md-6 col-12 px-1'>
                                        <div className='card'>
                                            <div className="card-body py-0">
                                                <OrderCard order={order.product} count={order.quantity} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default OrderDetails;
