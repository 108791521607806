const CompetitionIcon = () => {
    return (
        <svg fill="#fff" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512 512">
            <g>
                <g>
                    <path d="M363.232,271.105c-3.463-2.757-8.508-2.183-11.264,1.281c-2.885,3.626-6.016,7.132-9.306,10.42
			c-6.794,6.795-14.348,12.765-22.451,17.746c-3.772,2.317-4.951,7.256-2.633,11.027c1.514,2.464,4.144,3.82,6.837,3.82
			c1.432,0,2.881-0.384,4.19-1.188c9.17-5.636,17.714-12.387,25.394-20.067c3.717-3.716,7.252-7.676,10.514-11.774
			C367.272,278.906,366.698,273.863,363.232,271.105z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M386.405,188.126c-4.428,0-8.017,3.589-8.017,8.017c0,16.794-3.265,33.003-9.706,48.174
			c-1.729,4.076,0.172,8.782,4.248,10.513c1.023,0.434,2.084,0.639,3.13,0.639c3.12,0,6.086-1.833,7.382-4.886
			c7.286-17.164,10.98-35.481,10.98-54.44C394.422,191.715,390.833,188.126,386.405,188.126z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M439.85,175.299c-4.506,0-8.97,0.432-13.361,1.258v-15.154h17.637c4.428,0,8.017-3.589,8.017-8.017v-47.031
			c0-4.427-3.588-8.017-8.017-8.017h-25.653H93.528H67.875c-4.428,0-8.017,3.589-8.017,8.017v47.031
			c0,4.427,3.588,8.017,8.017,8.017h17.637v15.154c-4.391-0.826-8.855-1.258-13.361-1.258c-39.784,0-72.15,32.366-72.15,72.15
			s32.366,72.15,72.15,72.15c18.295,0,35.606-6.87,48.848-19.087c26.11,33.787,63.865,56.311,105.607,63.568v70.283
			c-38.349,6.652-73.374,26.285-99.25,55.782c-2.076,2.365-2.575,5.727-1.276,8.594c1.3,2.867,4.156,4.708,7.303,4.708h245.236
			c3.147,0,6.003-1.842,7.303-4.708c1.299-2.867,0.799-6.228-1.276-8.594c-25.876-29.497-60.9-49.131-99.25-55.782v-70.283
			c41.742-7.257,79.497-29.782,105.607-63.568c13.243,12.217,30.553,19.087,48.848,19.087c39.784,0,72.15-32.366,72.15-72.15
			S479.634,175.299,439.85,175.299z M75.891,145.37v-30.998h17.637h324.944h17.637v30.998h-17.637H93.528H75.891z M439.85,225.537
			c12.083,0,21.912,9.83,21.912,21.912s-9.83,21.912-21.912,21.912c-11.476,0-20.808-8.678-21.793-19.965
			c1.572-4.825,2.948-9.807,4.101-14.862C426.288,228.878,432.808,225.537,439.85,225.537z M93.942,249.397
			c-0.983,11.286-10.316,19.965-21.792,19.965c-12.083,0-21.912-9.83-21.912-21.912s9.83-21.912,21.912-21.912
			c7.042,0,13.562,3.342,17.69,8.998C90.995,239.59,92.37,244.571,93.942,249.397z M72.15,303.566
			c-30.942,0-56.117-25.174-56.117-56.117s25.175-56.117,56.117-56.117c4.527,0,9,0.552,13.361,1.62v3.19
			c0,5.369,0.266,10.737,0.764,16.079c-4.419-1.769-9.197-2.718-14.125-2.718c-20.924,0-37.946,17.022-37.946,37.946
			s17.022,37.946,37.946,37.946c12.388,0,23.346-5.839,30.261-14.969c2.78,5.762,5.875,11.376,9.298,16.804
			C101.229,297.669,87.108,303.566,72.15,303.566z M359.429,487.416H152.57c23.169-20.763,52.084-34.153,83.094-38.216
			c3.99-0.523,6.975-3.924,6.975-7.949v-74.619h26.723v74.619c0,4.025,2.984,7.426,6.975,7.949
			C307.346,453.263,336.261,466.653,359.429,487.416z M383.578,283.487c-0.003,0.003-0.005,0.006-0.007,0.011
			c-28.745,42.016-76.434,67.1-127.57,67.1s-98.826-25.084-127.571-67.1c-0.002-0.003-0.005-0.006-0.008-0.011
			c-7.966-11.646-14.281-24.299-18.771-37.61c-1.774-5.262-3.293-10.762-4.514-16.347c0-0.002-0.001-0.004-0.002-0.006
			c-2.381-10.919-3.589-22.151-3.589-33.382v-9.163v-25.576h308.91v25.576v9.163c0,11.231-1.208,22.463-3.59,33.382
			c0,0.002-0.001,0.004-0.002,0.006c-1.221,5.585-2.74,11.084-4.514,16.347C397.859,259.188,391.544,271.842,383.578,283.487z
			 M439.85,303.566c-14.958,0-29.078-5.897-39.559-16.336c3.424-5.428,6.518-11.042,9.298-16.804
			c6.915,9.129,17.872,14.969,30.26,14.969c20.924,0,37.946-17.022,37.946-37.946s-17.022-37.946-37.946-37.946
			c-4.929,0-9.707,0.949-14.125,2.718c0.498-5.342,0.764-10.71,0.764-16.079v-3.19c4.362-1.07,8.834-1.62,13.361-1.62
			c30.942,0,56.117,25.173,56.117,56.117S470.792,303.566,439.85,303.566z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M331.978,231.981c-1.123-3.218-4.159-5.375-7.569-5.375h-45.692l-15.183-41.754c-1.152-3.168-4.163-5.277-7.535-5.277
			s-6.382,2.109-7.535,5.277l-15.183,41.754h-45.692c-3.409,0-6.445,2.157-7.569,5.375c-1.123,3.219-0.088,6.797,2.582,8.918
			l38.617,30.68l-11.435,41.933c-0.909,3.329,0.416,6.866,3.287,8.779c2.871,1.915,6.646,1.778,9.369-0.342l33.558-26.1l33.559,26.1
			c1.443,1.122,3.181,1.689,4.922,1.689c1.546,0,3.096-0.446,4.445-1.347c2.872-1.914,4.195-5.45,3.287-8.779l-11.435-41.933
			l38.617-30.68C332.066,238.779,333.101,235.2,331.978,231.981z M276.667,262.313c-2.511,1.995-3.591,5.293-2.747,8.386
			l6.498,23.829l-19.496-15.163c-1.448-1.126-3.185-1.689-4.922-1.689c-1.737,0-3.474,0.563-4.922,1.689l-19.496,15.163
			l6.498-23.829c0.844-3.093-0.237-6.392-2.747-8.387l-24.764-19.674h28.329c3.37,0,6.382-2.109,7.535-5.277L256,211.049
			l9.568,26.313c1.152,3.168,4.163,5.277,7.535,5.277h28.329L276.667,262.313z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M277.378,29.93h-13.361V16.568c0-4.427-3.588-8.017-8.017-8.017c-4.428,0-8.017,3.589-8.017,8.017V29.93h-13.361
			c-4.428,0-8.017,3.589-8.017,8.017c0,4.427,3.588,8.017,8.017,8.017h13.361v13.361c0,4.427,3.588,8.017,8.017,8.017
			c4.428,0,8.017-3.589,8.017-8.017V45.963h13.361c4.428,0,8.017-3.589,8.017-8.017C285.395,33.519,281.806,29.93,277.378,29.93z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M397.094,29.93h-13.361V16.568c0-4.427-3.588-8.017-8.017-8.017c-4.428,0-8.017,3.589-8.017,8.017V29.93h-13.361
			c-4.428,0-8.017,3.589-8.017,8.017c0,4.427,3.588,8.017,8.017,8.017h13.361v13.361c0,4.427,3.588,8.017,8.017,8.017
			c4.428,0,8.017-3.589,8.017-8.017V45.963h13.361c4.428,0,8.017-3.589,8.017-8.017C405.111,33.519,401.522,29.93,397.094,29.93z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M166.213,29.93h-13.361V16.568c0-4.427-3.588-8.017-8.017-8.017c-4.428,0-8.017,3.589-8.017,8.017V29.93h-13.361
			c-4.428,0-8.017,3.589-8.017,8.017c0,4.427,3.588,8.017,8.017,8.017h13.361v13.361c0,4.427,3.588,8.017,8.017,8.017
			c4.428,0,8.017-3.589,8.017-8.017V45.963h13.361c4.428,0,8.017-3.589,8.017-8.017C174.23,33.519,170.641,29.93,166.213,29.93z"/>
                </g>
            </g>
        </svg>

    );
};

export default CompetitionIcon;
