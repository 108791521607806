import HomeIcon from "assets/Icons/HomeIcon";
import CompetitionIcon from "assets/Icons/CompetitionIcon";
import {NavLink} from "react-router-dom";
import StarOuroIcon from "assets/Icons/StarOuroIcon";
import CustomersIcon from "assets/Icons/CustomersIcon";
import ShopsIcon from "assets/Icons/ShopsIcon";
import BankAccountIcon from "assets/Icons/BankAccountIcon";
import MoneyTransfersIcon from "assets/Icons/MoneyTransfersIcon";
import FinancialManagementIcon from "assets/Icons/FinancialManagementIcon";
import PendingRequestsIcon from "assets/Icons/PendingRequestsIcon";
import AffiliatesIcon from "assets/Icons/AffiliatesIcon";
import CardsIcon from "assets/Icons/CardsIcon";
import NotificationsIcon from "assets/Icons/NotificationsIcon";
import ReportsIcon from "assets/Icons/ReportsIcon";
import SettingsIcon from "assets/Icons/SettingsIcon";
import TechnicalSupportIcon from "assets/Icons/TechnicalSupportIcon";
import LogOutIcon from "assets/Icons/LogOutIcon";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useContext} from "react";
import LanguageContext from "Components/Languages/LanguageContext";
import SideNavBar from "./SideNavBar";
import dashboardText from "../../Components/PagesTitle/PagesTitle.json";
import DateIconVendor from "assets/IconsVendor/DateIconVendor";
import MarketingIcon from "assets/IconsVendor/MarketingIcon";
import {useTranslation} from "react-i18next";
import GiftIcon from "assets/Icons/GiftIcon";

const SideBarMain = ({isOpen, closeToggleSidebar, toggleSidebar}) => {
    const {t} = useTranslation();
    const {language} = useContext(LanguageContext);
    // LINKS SIDEBAR
    const linksMenuBar = [
        {
            text: t("homePage"),
            icon: <HomeIcon/>,
            path: "/dashboardAdmin/homedashboard",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.ouroTopPage}`),
            icon: <StarOuroIcon/>,
            path: "/dashboardAdmin/ouroTopPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.customersPage}`),
            icon: <CustomersIcon/>,
            path: "/dashboardAdmin/customersPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`Orders`),
            icon: <CustomersIcon/>,
            path: "/dashboardAdmin/OrdersPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.shops}`),
            icon: <ShopsIcon/>,
            path: "/dashboardAdmin/shops",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.competition}`),
            icon: <CompetitionIcon/>,
            path: "/dashboardAdmin/competitions",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.debtBankAccount}`),
            icon: <BankAccountIcon/>,
            path: "/dashboardAdmin/debtBankAccount",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`operations room`),
            icon: <BankAccountIcon/>,
            path: "/dashboardAdmin/livefinance",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.moneyTransfers}`),
            icon: <MoneyTransfersIcon/>,
            path: "/dashboardAdmin/moneyTransfers",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`Ouro Gifts`),
            icon: <GiftIcon/>,
            path: "/dashboardAdmin/GiftsPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.financialManagement}`),
            icon: <FinancialManagementIcon/>,
            path: "/dashboardAdmin/financialManagement",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.pendingOrders}`),
            icon: <PendingRequestsIcon/>,
            path: "/dashboardAdmin/pendingOrders",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.eventsMain}`),
            icon: <DateIconVendor/>,
            path: "/dashboardAdmin/eventsMain",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.marketingCampaignsMain}`),
            icon: <MarketingIcon/>,
            path: "/dashboardAdmin/marketingCampaignsMain",
            toggleSidebar: closeToggleSidebar,
        },
        // {
        //   text: t(`${dashboardText.dashboard.affiliatesPage}`) ,
        //   icon: <AffiliatesIcon />,
        //   path: "/dashboardAdmin/affiliatesPage",
        //   toggleSidebar: closeToggleSidebar
        // },
        {
            text: t(`${dashboardText.dashboard.cardsPage}`),
            icon: <CardsIcon/>,
            path: "/dashboardAdmin/cardsPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.notificationsPage}`),
            icon: <NotificationsIcon/>,
            path: "/dashboardAdmin/notificationsPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.reportsPage}`),
            icon: <ReportsIcon/>,
            path: "/dashboardAdmin/reportsPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.controlDashboard}`),
            icon: <SettingsIcon/>,
            path: "/dashboardAdmin/controlDashboard",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`${dashboardText.dashboard.technicalSupportPage}`),
            icon: <TechnicalSupportIcon/>,
            path: "/dashboardAdmin/technicalSupportPage",
            toggleSidebar: closeToggleSidebar,
        },
        {
            text: t(`logOut`),
            icon: <LogOutIcon/>,
            path: "/",
            toggleSidebar: closeToggleSidebar,
        },
    ];
    return (
        <>
            <SideNavBar
                isOpen={isOpen}
                closeToggleSidebar={closeToggleSidebar}
                toggleSidebar={toggleSidebar}
                routeLogo={"/dashboardAdmin/homedashboard"}
            >
                {linksMenuBar.map((link) => (
                    <li
                        className="nav-item"
                        key={link.path}
                        data-bs-toggle="tooltip"
                        data-bs-placement={`${language === "en" ? "right" : "left"}`}
                        data-bs-title="Tooltip on left"
                    >
                        {isOpen === false ? (
                            <OverlayTrigger
                                placement={`${language === "en" ? "right" : "left"}`}
                                overlay={
                                    <Tooltip id={`tooltip-${link.path}`}>{link.text}</Tooltip>
                                }
                            >
                                <NavLink
                                    className={`nav-link ${link.newClass}`}
                                    to={link.path}
                                    onClick={link.toggleSidebar}
                                >
                                    <div className="icon-link-nav">{link.icon}</div>
                                    <span className="text-link"> {link.text}</span>
                                </NavLink>
                            </OverlayTrigger>
                        ) : (
                            <NavLink
                                className={`nav-link ${link.newClass}`}
                                to={link.path}
                                onClick={link.toggleSidebar}
                            >
                                <div className="icon-link-nav">{link.icon}</div>
                                <span className="text-link"> {link.text}</span>
                            </NavLink>
                        )}
                    </li>
                ))}
            </SideNavBar>
        </>
    );
};

export default SideBarMain;
