import iconImg from "../../../../../../assets/images/dashboardVendor/HomeImg/dollar-circle.svg";
import iconImg2 from "../../../../../../assets/images/dashboardVendor/HomeImg/bill.svg";
import icon1 from "../../../../../../assets/images/dashboard/iconsBox/4.svg";
import iconImg4 from "../../../../../../assets/images/dashboardVendor/HomeImg/bag-happy.svg";
import CardHero from "Dashboard/Components/Ui/CardHero/CardHero";
import {useTranslation} from "react-i18next";

const HeaderDays = ({reportsDats}) => {
    const {t} = useTranslation();

    const cardItem = [
        {
            id: 0,
            lineColor: "warningLine",
            textTop: t("Customers from Ouro"),
            iconImg: icon1,
            numberInfo: reportsDats?.total_customers,
            typeNumber: false,
        },
        {
            id: 1,
            lineColor: "darkLine",
            textTop: t("Total number of orders"),
            iconImg: iconImg4,
            numberInfo: reportsDats?.total_orders,
            typeNumber: false,
        },
        {
            id: 2,

            lineColor: "blueLine",
            textTop: t("Number Of Tickets"),
            iconImg: iconImg2,
            numberInfo: reportsDats?.store_avg,
           // typeNumberInfo: reportsDats?.currency,
            typeNumber: true,
        },
        {
            id: 3,

            lineColor: "greenLine",
            textTop: t("Sum invoice price"),
            iconImg: iconImg,
            numberInfo: reportsDats?.order_avg,
            typeNumberInfo: reportsDats?.currency,
            typeNumber: true,
        },
    ];

    return (
        <>
            {/* ========== START HEADER CLIENT FILE =========== */}
            <div className="header-client-file header-home-vendor header-reports-vendor margin-top">
                <div className="row g-3">
                    {/* ========== START COL ============ */}
                    <div className="col-12">
                        {/* ========== START RIGHT CONTENT FILE =========== */}
                        <div className="right-content-header">
                            {/* ============== START ROW ========= */}
                            <div className="row g-3">
                                {cardItem.map((item) => (
                                    <div className="col-12 col-sm-6 col-md-3" key={item.id}>
                                        <CardHero
                                            lineBg={item.lineColor}
                                            textTop={item.textTop}
                                            iconImg={item.iconImg}
                                            numberInfo={`${item.numberInfo}`}
                                            typeNumber={item.typeNumber}
                                            typeNumberInfo={item.typeNumberInfo}
                                            isUpPercentage={item.isUpPercentage}
                                            isDownPercentage={item.isDownPercentage}
                                            numPercentage={item.numPercentage}
                                            textPercentageDate={item.textPercentageDate}
                                        />
                                    </div>
                                ))}
                            </div>
                            {/* ============== END ROW ========= */}
                        </div>
                        {/* ========== END RIGHT CONTENT FILE =========== */}
                    </div>
                    {/* ========== END COL ============ */}
                </div>
            </div>
            {/* ========== END HEADER CLIENT FILE =========== */}
        </>
    );
};

export default HeaderDays;
