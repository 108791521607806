import axios from "axios";
import Cookies from "js-cookie";
import {BASE_URL} from "./config";

const axiosInstance = axios.create({
    baseURL: BASE_URL, // Replace with your base URL
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Accept-Language": localStorage?.i18nextLng
            ? localStorage?.i18nextLng
            : "en",
    },

});

axiosInstance.interceptors.request.use(
    (config) => {
        let currentLanguage = localStorage.getItem("i18nextLng") || 'ar';

        const token = Cookies.get("token");

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        config.headers['Accept-Language'] = currentLanguage;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const isAuthenticated = () => {
    const token = Cookies.get("token");
    return !!token;
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.location.replace("/");
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
