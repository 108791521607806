import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef, useMemo } from "react";
import axiosInstance from "../../../../../../../../axios";
import moment from "moment";
import DataTable from "../../../../../../../../Components/DataTable/DataTable";
import "./Charts.css";

function SaleReport({ event }) {
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const canvasRef = useRef(null);

    const columnDefs = useMemo(
        () => [
            {
                headerName: "#",
                field: "id",
                width: "100px",
            },
            {
                headerName: i18n.language === "ar" ? "التاريخ" : "Date",
                field: "date",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "تذكرة" : "Ticket",
                field: "title",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "الكمية" : "Quantity",
                field: "quantity_sold",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "الإيرادات" : "Revenue",
                field: "revenue",
                flex: 1,
            }
        ],
        [i18n.language]
    );

    function fetchReports() {
        axiosInstance.get(`/admin/ticket-sales/daily-stats/${event.id}`)
            .then((response) => {
                const formattedData = response.data.data.map(item => ({
                    id: item.id,
                    date: moment(item.date).format("DD-MM-YYYY"),
                    tickets: item.tickets.map(ticket => ({
                        type: ticket.ticket_type,
                        title: ticket.ticket_title,
                        quantity_sold: ticket.quantity_sold,
                        revenue: parseFloat(ticket.revenue)
                    }))
                }));
                setData(formattedData);
                console.log("Fetched data:", formattedData);
            })
            .catch(error => {
                console.error("Error fetching reports:", error);
            });
    }

    useEffect(() => {
        fetchReports();
    }, [event.id]);

    // Draw chart
    useEffect(() => {
        if (!canvasRef.current || !data.length) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = canvas.width;
        const height = canvas.height;

        // Clear canvas
        ctx.clearRect(0, 0, width, height);

        // Calculate margins
        const margin = { top: 40, right: 30, bottom: 60, left: 60 };
        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        // Get unique ticket types
        const ticketTypes = [...new Set(data.flatMap(d => d.tickets.map(t => t.type)))];

        // Find min and max values
        const maxRevenue = Math.max(...data.flatMap(d => d.tickets.map(t => t.revenue)));
        const minRevenue = 0; // Starting from 0 for better visualization

        // Calculate bar width
        const groupWidth = chartWidth / data.length;
        const barWidth = (groupWidth * 0.8) / ticketTypes.length; // 80% of space for bars
        const barSpacing = groupWidth * 0.2 / (ticketTypes.length + 1); // 20% for spacing

        // Draw grid
        ctx.strokeStyle = '#e0e0e0';
        ctx.lineWidth = 0.5;
        for (let i = 0; i <= 5; i++) {
            const y = margin.top + (chartHeight * (1 - i / 5));
            ctx.beginPath();
            ctx.moveTo(margin.left, y);
            ctx.lineTo(width - margin.right, y);
            ctx.stroke();

            // Draw Y-axis labels
            ctx.fillStyle = '#666';
            ctx.textAlign = 'right';
            ctx.fillText(
                Math.round(minRevenue + ((maxRevenue - minRevenue) * (i / 5))),
                margin.left - 10,
                y + 5
            );
        }

        // Define colors for different ticket types
        const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c'];

        // Draw bars
        data.forEach((dayData, dayIndex) => {
            const groupX = margin.left + (dayIndex * groupWidth);

            ticketTypes.forEach((type, typeIndex) => {
                const ticket = dayData.tickets.find(t => t.type === type);
                if (ticket) {
                    const barHeight = (ticket.revenue / maxRevenue) * chartHeight;
                    const x = groupX + barSpacing + (typeIndex * (barWidth + barSpacing));
                    const y = height - margin.bottom - barHeight;

                    // Draw bar
                    ctx.fillStyle = colors[typeIndex % colors.length];
                    ctx.fillRect(x, y, barWidth, barHeight);
                }
            });
        });

        // Draw X-axis labels
        ctx.fillStyle = '#666';
        ctx.textAlign = 'center';
        data.forEach((point, i) => {
            const x = margin.left + (i * groupWidth) + (groupWidth / 2);
            ctx.save();
            ctx.translate(x, height - margin.bottom + 10);
            ctx.rotate(-Math.PI / 4);
            ctx.fillText(point.date, 0, 0);
            ctx.restore();
        });

        // Draw axis lines
        ctx.strokeStyle = '#666';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(margin.left, margin.top);
        ctx.lineTo(margin.left, height - margin.bottom);
        ctx.lineTo(width - margin.right, height - margin.bottom);
        ctx.stroke();

        // Draw legend
        const legendY = margin.top - 20;
        ticketTypes.forEach((type, index) => {
            const legendX = margin.left + (index * 120);
            
            // Draw color box
            ctx.fillStyle = colors[index % colors.length];
            ctx.fillRect(legendX, legendY, 15, 15);
            
            // Draw type name
            ctx.fillStyle = '#666';
            ctx.textAlign = 'left';
            ctx.fillText(type, legendX + 20, legendY + 12);
        });

    }, [data]);

    return (
        <>
            <div style={{ width: '100%', height: 400, marginBottom: 20 }}>
                <canvas
                    ref={canvasRef}
                    width={800}
                    height={400}
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white'
                    }}
                />
            </div>
            <DataTable
                rowData={data.flatMap(item => item.tickets.map(ticket => ({
                    id: item.id,
                    date: item.date,
                    type: ticket.type,
                    title: ticket.title,
                    quantity_sold: ticket.quantity_sold,
                    revenue: ticket.revenue
                })))}
                columnDefs={columnDefs}
            />
        </>
    );
}

export default SaleReport;