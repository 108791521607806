import img from "../../../../../../../../assets/images/user/01.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import EditIcon from "assets/Icons/EditIcon";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import {AgGridReact} from "ag-grid-react";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import DataTable from "Components/DataTable/DataTable";
import {Link, useNavigate} from "react-router-dom";
import EyeIcon from "assets/Icons/EyeIcon";
import OrderEdit from "./OrderEdit";

const OrdersData = ({information}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(false);

    const [rowsData, setRowsData] = useState(
        information.branches.map((barnch) => ({
            id: barnch.id,
            customer: barnch?.customer,
            address: barnch.address,
            phone: barnch.phone,
            lat: barnch.lat,
            lng: barnch.lng,
            status: barnch.status,
            is_main: barnch.is_main,
            Ouro: barnch.ouro,

            created_at: moment(barnch.created_at).format("DD-MM-YYYY , HH:MM"),
            updated_at: moment(barnch.updated_at).format("DD-MM-YYYY , HH:MM"),
        }))
    );

    const [filteredData, setFilteredData] = useState(
        information.orders.map((order) => ({
            id: order.id,
            customer: order?.customer,
            address: order?.branch?.address,
            phone: order?.branch?.phone,
            lat: order.lat,         
            lng: order.lng,
            status: order.payment_status,
            is_main: order.is_main,
            price: order.price,
            customer_name: order.customer ? order.customer.name : order.customer_name,
            customer_phone: order.customer ? order.customer.phone : order.customer_phone,
            points: order.points,
            Ouro: order.ouro,
            created_at: moment(order.created_at).format("DD-MM-YYYY , HH:MM"),
            updated_at: moment(order.updated_at).format("DD-MM-YYYY , HH:MM"),
        }))
    );

    const headerNames = {
        id: {
            en: "#",
            ar: "#",
        },
        customer: {
            en: "Customer ",
            ar: " العميل",
        },
        // address: {
        //   en: "Address",
        //   ar: "العنوان",
        // },
        // phone: {
        //   en: "Phone",
        //   ar: "الهاتف",
        // },
        price: {
            en: "price",
            ar: "الاجمالي",
        },
        points: {
            en: "points",
            ar: "النقاط",
        },
        Ouro: {
            en: "Ouro Profits",
            ar: "ارباح اورو",
        },
        status: {
            en: "Status",
            ar: "الحالة",
        },
        payment_status: {
            en: "payment status",
            ar: "حالة الدفع",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const columnDefs = [
        {
            headerName: headerNames.id[i18n?.language],
            field: "id",
            flex: 0.5,
        },
        {
            headerName: headerNames.customer[i18n?.language],
            field: "customer",
            flex: 0.5,
            cellRenderer: (params) => {
                console.log(params.data, 'data');

                return (
                    params.data.customer ?
                        <Link
                            to={`/dashboardAdmin/clientFile/${params.data.customer?.id}`}
                            className="d-flex gap-2 align-items-center"
                        >
                            <img
                                style={{width: "32px", height: "32px", borderRadius: "50%"}}
                                src={params.data.customer?.image}
                                alt="img"
                            />
                            <span>{params.data.customer?.name}</span>
                        </Link>
                        : <p>{params.data.customer_name}</p>
                );
            },
        },
        // {
        //   headerName: headerNames.address[i18n?.language],
        //   field: "address",
        //   flex: 2,
        // },
        // {
        //   headerName: headerNames.phone[i18n?.language],
        //   field: "phone",
        //   flex: 1,
        // },

        {
            headerName: headerNames.price[i18n?.language],
            field: "price",
            flex: 1,
        },
        {
            headerName: headerNames.Ouro[i18n?.language],
            field: "Ouro",
            flex: 1,
        },
        {
            headerName: headerNames.points[i18n?.language],
            field: "points",
            flex: 1,
        },
        {
            headerName: headerNames.status[i18n?.language],
            field: "status",
            flex: 1,
            cellRenderer: (params) => {
                return (
                    <span>
            {" "}
                        {params?.data?.status == null
                            ? t("unKnown")
                            : params?.data?.status}{" "}
          </span>
                );
            },
        },
        // {
        //   headerName: headerNames.created_at[i18n?.language],
        //   field: "created_at",
        //   flex: 1,
        // },
        // {
        //   headerName: headerNames.updated_at[i18n?.language],
        //   field: "updated_at",
        //   flex: 1,
        // },
        {
          headerName: headerNames.payment_status[i18n?.language],
          field: "payment_status",
          flex: 1,
            cellRenderer: (params) => {
                return (
                    <span>
                        {params?.data?.payemt_status === 1
                            ? i18n.language === 'ar' ? 'مدفوع': 'paid'
                            : i18n.language === 'ar' ? 'غير مدفوع': 'unpaid'
                        }
                    </span>
                );
            },
        },
        {
            headerName: i18n?.language === "ar" ? "الإجراءات" : "Actions",
            field: "Actions",
            flex: 0.5,
            cellRenderer: (params) => (
                <>
                    <div
                        onClick={() => {
                            navigate(`/dashboardAdmin/order/${params.data.id}`);
                        }}
                        className=" cursor-pointer-event"
                    >
                        <EyeIcon/>
                    </div>

                    <div
                        onClick={() => {
                            setShowEditModal(true)
                            setSelectedOrder(params.data)
                        }}
                        className=" ms-2 cursor-pointer-event"
                    >
                        <EditIcon/>
                    </div>

                </>
            ),
        },
    ];

    useEffect(() => {
    }, []);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    return (
        <>
            <OrderEdit
                show={showEditModal}
                setShow={setShowEditModal}
                order={selectedOrder}
                store={information}
            />

            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={false}
                selectCategory={false}
                selectPay={true}
            />
            <div className="all-data-table-shop card-style-2 table-border--1  margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = rowsData.filter((row) => {
                            return (
                                row.id.toString().toLowerCase().includes(searchValue) ||
                                row.address.toLowerCase().includes(searchValue) ||
                                row.phone.toLowerCase().includes(searchValue)
                            );
                        });
                        setFilteredData(filteredData);
                    }}
                    functionIconSearch={() => {
                        console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport
                                dataName={"store"}
                                dataExport={information.branches}
                            />
                        </>
                    }
                />

                <DataTable rowData={filteredData} columnDefs={columnDefs}/>
            </div>
        </>
    );
};

export default OrdersData;
